import { AlertService } from 'src/app/services/alert/alert.service';
import { ButtonComponent } from '@shared/components/atoms/buttons/button/button.component';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, input, Output, ViewChild } from '@angular/core';
import { DataMappingService } from 'src/app/services/data-mapping/data-mapping.service';
import { EmpImportFileFields } from 'src/app/model/interfaces/data-mapping.interface';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from '../../cogsig-signatures/signature-preview/safe.pipe';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import * as XLSX from 'xlsx';

@Component({
  selector: 'mt-upload-csv',
  templateUrl: './upload-csv.component.html',
  styleUrls: ['./upload-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, ReactiveFormsModule, SafePipe, TranslocoModule]
})
export class UploadCsvComponent {
  /**
   * Holds the HTML element refence of choose file
   */
  @ViewChild('sheetInput') sheetInput!: ElementRef;

  /**
   * Upload csv help line
   * @defaultValue 'import_emp_follow_the_requirement'
   */
  upload_csv_help_line = input('import_emp_follow_the_requirement');

  /**
   * Upload csv template line
   * @defaultValue 'no_proper_excel_template'
   */
  upload_csv_template_line = input('no_proper_excel_template');

  /**
   * Upload csv warning line
   * @defaultValue 'EmployeestoMailtasticthepresenttable'
   */
  upload_csv_warning_line = input('EmployeestoMailtasticthepresenttable');

  /**
   * Boolean to check whether to add one more button or not
   * @defaultValue 'upload_csv_append_warning_line'
   */
  upload_csv_append_warning_line = input('upload_csv_append_warning_line');

  /**
   * Upload csv german help url
   * @defaultValue 'https://help.mailtastic.com/de/articles/2090751-mitarbeiter-importieren-externe-quellen'
   */
  german_help_url = input('https://help.mailtastic.com/de/articles/2090751-mitarbeiter-importieren-externe-quellen');

  /**
   * Upload csv english help url
   * @defaultValue 'https://help.mailtastic.com/en/articles/2090751-import-employees-external-sources'
   */
  english_help_url = input('https://help.mailtastic.com/en/articles/2090751-import-employees-external-sources');

  /**
   * Upload csv german download url
   * @defaultValue 'https://www.mailtastic.de/downloads/MitarbeiterImportierenVorlage.xlsx'
   */
  german_download_url = input('https://www.mailtastic.de/downloads/MitarbeiterImportierenVorlage.xlsx');

  /**
   * Upload csv english download url
   * @defaultValue 'https://www.mailtastic.de/downloads/EmployeeImportTemplate.xlsx'
   */
  english_download_url = input('https://www.mailtastic.de/downloads/EmployeeImportTemplate.xlsx');

  /**
   * Extra padding inside csv compoent
   * @defaultValue true
   */
  isExtraPaddingNeeded = input(true);

  /**
   * No Input Data Message
   * @defaultValue 'html.EXCEL_EMAIL_MISSINS'
   */
  noInputDataMessage = input('html.EXCEL_EMAIL_MISSINS');

  /**
   * Boolean to check whether to add one more button or not
   * @defaultValue false
   */
  isShowAppendButton = input(false);

  /**
   * Triggers if file is selected or not
   */
  @Output() isFileSelected = new EventEmitter<void>();

  /**
   * Form control to hold append data or replace data
   */
  isAppendData = new FormControl('false');

  constructor(
    private alert: AlertService,
    private dataMappingService: DataMappingService,
    public translate: TranslocoService
  ) {}

  /**
   * Processes the sheet file to import from
   * @param event - Sheet file containing employee information
   */
  processFile(event: Event): void {
    /* wire up file reader */
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    const checkFileExtention = file.name.substr(file.name.lastIndexOf('.') + 1);
    if (!(checkFileExtention === 'xlsx' || checkFileExtention === 'csv')) {
      void this.alert.defaultErrorMessage(this.translate.translate('EXCEL_WRONG_FORMAT') as string);
      this.resetChooseFile();
      return;
    }

    const reader: FileReader = new FileReader();

    reader.onload = e => {
      let uploadedFileData = [];
      /* read workbook */
      const bstr = e.target?.result;
      const workbook: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      if (workbook.SheetNames.length > 1) {
        void this.alert.defaultErrorMessage(
          this.translate.translate('Multiple sheets are not allowed in a file') as string
        );
        this.resetChooseFile();
        return;
      }

      /* grab first sheet */
      const wsname: string = workbook.SheetNames[0];
      const ws: XLSX.WorkSheet = workbook.Sheets[wsname];

      /* save data */
      const headers: string[] = XLSX.utils.sheet_to_json(ws, { header: 1 });
      uploadedFileData = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (uploadedFileData.length === 0) {
        void this.alert.defaultErrorMessage(this.translate.translate(this.noInputDataMessage()) as string);
        uploadedFileData = [];
        this.resetChooseFile();
        return;
      } else {
        this.dataMappingService.updateUploadedFileData({
          headers: headers[0],
          fileData: uploadedFileData as EmpImportFileFields[],
          fileName: file.name,
          isAppendData: this.isAppendData.getRawValue() === 'true'
        });
        this.isFileSelected.emit();
      }
    };

    reader.readAsBinaryString(file);
    this.resetChooseFile();
  }

  /**
   * Reset the file input
   */
  resetChooseFile(): void {
    this.sheetInput.nativeElement.value = null;
  }
}
